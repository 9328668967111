
/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');
require('slick-carousel');
const fancybox = require('@fancyapps/fancybox');

$(".list-sub li").on({
    mouseenter: function () {
        var target=$(this).data("target");
        $("#"+target).addClass("active").siblings("div").removeClass("active");
        console.log(target.split("-")[1]);
        if($("#sub-sub-"+(target.split("-")[1])).length>0){
            $("#sub-sub-"+(target.split("-")[1])).addClass("active").siblings().removeClass("active");
        }else{
            $(".sub-sub").removeClass('active');
        }
    }
//    mouseleave: function () {
//        var target=$(this).data("target");
//        $("#"+target)
//    }
});

$('.carousel').slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
});

$(window).on('scroll', function () {
    var menu = $('.sticky-top');
    var menu_offset = menu.offset();
    if ($(window).scrollTop() > 1) {
        if (!menu.hasClass('sticky-on')) {
            menu.addClass('sticky-on');
        }
    } else {
        menu.removeClass('sticky-on');
    }
});

$(function(){
  $('[data-fancybox]').fancybox({
      toolbar  : false,
      smallBtn : true,
      maxWidth : 1200,
      maxHeight : 900,
      animationEffect: 'zoom',
      type: 'iframe',
      iframe : {
          preload : false
      }
    })
});